const users = [
  {
    userPic: "/images/user.png",
    name: "Alberth",
    email: "alberthcruz99@outlook.com",
    password: "secret",
    balance: 1000,
    transactionHistory: [],
    loggegIN: true,
  },
];

export default users;
